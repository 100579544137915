import React from 'react'
import pegaso_min from '../img/pegaso_min.jpg';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

import Footer from './Footer';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';


const transition = {
    duration: 0.8,
    ease: [0.43, 0.13, 0.23, 0.96]
  };
  
  const backVariants = {
    exit: { opacity: 0, transition },
    enter: { x: 0, opacity: 1, transition: { delay: 0.8, ...transition } }
  };

const Pegaso2 = () => {

    return (
        
        <div>
        <motion.div
        variants={backVariants}
        initial="exit" 
        animate="enter" 
        exit="exit">
        <Container maxWidth="md" className="pegasocont">
            

            
            
            <div className="cons">
            <Link to="/restauracion" className="link">
                <h1 className="titulos">
                    CONSERVACIÓN
                    <br/>Y RESTAURACIÓN
                </h1>
            </Link>
            </div>
            
            <div className="flex-container">
                <div className="contdig">
                    <Link to={{pathname:"https://www.instagram.com/stories/highlights/18020058269223601/"}} target="_blank" className="link">
                    <h2 className="titulos">
                        DIGITAL <br/> IMPRESIÓN
                    </h2>
                    </Link>
                </div>
                <div className="imgcont">
                <img 
                className="pegaso"
                alt="El sueño de Pegaso, Gonzalo Morales"
                src={pegaso_min}/>
                </div>
            </div>

            <div className="rev">
            <Link to={{pathname:"https://www.instagram.com/stories/highlights/18029730116016980/"}} target="_blank" className="link">
                <h2 className="titulos">
                    REVELADO DE PELÍCULA<br/>
                    (B/N y COLOR)
                </h2>
            </Link>
            </div>
            
   
       
       
        </Container>
        </motion.div>
        <Container maxWidth="xl" className="footerpega">
        <table border="0" align="center">
            <tr>
                <td>
                <div className="direc">
                <a rel="noopener noreferrer" href="https://www.google.com.mx/maps/place/Eme+Espacio+De+Arte/@19.4270909,-99.2056337,15.5z/data=!4m8!1m2!2m1!1sPrado+Norte+135+PB,+Lomas+de+Chapultepec+11000,+Ciudad+de+M%C3%A9xico!3m4!1s0x85d201f6773eaaab:0x691d67db9e66af46!8m2!3d19.4276284!4d-99.206239" target="_blank">Prado Norte 135 PB, Lomas de Chapultepec<br/> 11000, Ciudad de México</a>
                </div>
                </td>
                <td>
                <div className="contac">
                <a rel="noopener noreferrer" href="mailto:contacto@eme-lab.com" target="_blank">Más información:<br/>contacto@eme-lab.com</a>
                </div>
                </td>
                <td>
                <div className="inst">
                <a rel="noopener noreferrer" href="https://www.instagram.com/emelaboratoriodearte/" target="_blank">Ig. @emelaboratoriodearte</a>
                <br/> <a>t. (521) 3872 7556/(55) 4447 6087</a> 
                </div>
                </td>
                
            </tr>
        </table>
        </Container>
     </div>   

      
    
    
    )
}

export default Pegaso2
