import React from 'react'
import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import { motion } from "framer-motion";
import Footer from './Footer';


const transition = {
    duration: 0.8,
    ease: [0.43, 0.13, 0.23, 0.96]
  };
  
  const backVariants = {
    exit: { x: 100, opacity: 0, transition },
    enter: { x: 0, opacity: 1, transition: { delay: 0.8, ...transition } }
  };

const Revelado= () => {
    return (
        <div>
        <motion.div
        variants={backVariants}
        initial="exit" 
        animate="enter" 
        exit="exit">
       
            <Container maxWidth="md">
            
            <div className="containerev">
            <div className="boton">
            <Link to='/'>
                <IconButton >
                <ArrowBackIcon  fontSize="large" color="inherent"/>
                </IconButton>
            </Link>
            </div>    
            <h1 className="tituloin">Revelado</h1>
            <br/>
            <Container maxWidth="md">
            <table border="1" className="tabpre">
                <tr>
                    <td className="tabtit">
                        B/N y Color
                    </td>
                    <td className="tabtit">
                        Precio
                    </td>
                </tr>
                <tr>
                    <td className="tabitem">
                    135 mm
                    </td>
                    <td className="tabitem">
                    $160
                    </td>
                </tr>
                <tr>
                    <td className="tabitem">
                    120 mm
                    </td>
                    <td className="tabitem">
                    $155
                    </td>
                </tr>
                <tr>
                    <td className="tabitem">
                    Placas 4x5
                    </td>
                    <td className="tabitem">
                    $90
                    </td>
                </tr>
            </table>
            <br/>
            <div className="asteri">
                *Diapositivas sobre pedido
            </div>
            </Container>
            <br/>
            <h1 className="tituloin">Digitalización<br/></h1>
            <h2 className="textos">Digitalización de negativos con
                sensor CMOS Full Frame de 50.6 megapixeles<br/>
                Cama plana Epson V850 Pro</h2>  <br/>
        
           
      
            <Container maxWidth="md">

            <table border="1" className="tabpre">
                <th colSpan="7" className="tabhead">Cama Plana</th>
                
                <tr>
                    <td rowSpan="6" className="epsonrow">
                        <div className="epson">
                    EPSON Perfection<br/>
                V850 Pro</div>
                    </td>
                </tr>
                <tr>
                    <td className="tabtit">
                    Formato
                    </td>
                    <td className="tabtit">
                    1600 DPI
                    </td>
                    <td className="tabtit">
                    2400 DPI
                    </td>
                    <td className="tabtit">
                    3200 DPI
                    </td>
                    <td className="tabtit">
                    4800 DPI
                    </td>
                    <td className="tabtit">
                    6400 DPI
                    </td>
                </tr>
                <tr>
                    <td className="tabtit">
                    35 mm <br/>(35x24 mm)
                    </td>
                    <td className="tabtit">
                    $90
                    </td>
                    <td className="tabtit">
                    $160
                    </td>
                    <td className="tabtit">
                    $200
                    </td>
                    <td className="tabtit">
                    $250
                    </td>
                    <td className="tabtit">
                    ---
                    </td>
                </tr>
                <tr>
                    <td className="tabtit">
                        120 mm <br/>
                        (6x4.5, 6x6, 6x7)
                    </td>
                    <td className="tabtit">
                        $150
                    </td>
                    <td className="tabtit">
                        $250
                    </td>
                    <td className="tabtit">
                        $350
                    </td>
                    <td className="tabtit">
                        $450
                    </td>
                    <td className="tabtit">
                        $550
                    </td>
                </tr>
                <tr>
                    <td className="tabtit">
                        Placa 4x5"
                    </td>
                    <td className="tabtit">
                        $300
                    </td>
                    <td className="tabtit">
                        $500
                    </td>
                    <td className="tabtit">
                        $700
                    </td>
                    <td className="tabtit">
                        $900
                    </td>
                    <td className="tabtit">
                        ---
                    </td>
                </tr>
          
            </table>

            </Container>
            
            <br/>
            <div className="asteri">
                *Todos los precios incluyen IVA
            </div>
            <br/><br/>
             </div>
            </Container>
    


        </motion.div>
     
        </div>
    )
}

export default Revelado
