import React from 'react'
import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';
import { motion } from "framer-motion";
import Footer from './Footer';


const transition = {
    duration: 0.8,
    ease: [0.43, 0.13, 0.23, 0.96]
  };
  
  const backVariants = {
    exit: { x: 100, opacity: 0, transition },
    enter: { x: 0, opacity: 1, transition: { delay: 0.8, ...transition } }
  };

const Restauracion = () => {
    return (
        <div>
        <motion.div
        variants={backVariants}
        initial="exit" 
        animate="enter" 
        exit="exit">
           
            <Container maxWidth="md">
            <div className="container">
            <div className="boton">
            <Link to='/'>
                <IconButton >
                <ArrowBackIcon  fontSize="large" color="inherent"/>
                </IconButton>
            </Link>
            </div>    
            <h1 className="tituloin">Conservación y restauracion<br/>
de fotografías y documentos</h1><br/>
            

            <h2 className="textos">Estabilización y consolidación de emulsiones.<br/>
Limpieza de obras.<br/>
Tratamientos para la detención de la degradación.<br/>
Restauración de marcos y sistemas de montaje.<br/>
Creación de embalajes de protección especiales.<br/>
Producción de facsímiles para exposición.<br/>
Montajes para exposición siguiendo normas de durabilidad<br/>
y conservación museográfica.</h2><br/><br/><br/>
            <h1 className="tituloin">Digitalización de materiales<br/>
fotográficos y obras de arte</h1><br/>
            <h2 className="textos">Las fotografías, inevitablemente, se deterioran con el
paso del tiempo.<br/>
Al digitalizar una obra, aseguramos la posibilidad de
poder apreciar y<br/> estudiar esta pieza, aún cuando el
original sufra deterioros o pérdidas.
<br/>
Acondicionamiento, limpieza y preparación de piezas
para digitalización.<br/>
Digitalización y Reproducción de obras de arte
Inserción de metadatos y catalogación.</h2>
             </div>
             
            </Container>
    
        
       

         
        </motion.div>
   
        </div>
    )
}

export default Restauracion
