import React from 'react'
import Container from '@material-ui/core/Container';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import IconButton from '@material-ui/core/IconButton';

import { motion } from "framer-motion";

import tabla1 from '../img/tabla1.png';
import tabla2 from '../img/tabla2.png';

const transition = {
    duration: 0.8,
    ease: [0.43, 0.13, 0.23, 0.96]
  };
  
  const backVariants = {
    exit: { x: 100, opacity: 0, transition },
    enter: { x: 0, opacity: 1, transition: { delay: 0.8, ...transition } }
  };
  


const Digital = () => {
    return (
        <div>
        <motion.div
        variants={backVariants}
        initial="exit" 
        animate="enter" 
        exit="exit">
          
            <Container maxWidth="md">
            <div className="containerev">
            <div className="boton">
            <Link to='/'>
                <IconButton >
                <ArrowBackIcon  fontSize="large" color="inherent"/>
                </IconButton>
            </Link>
            </div>    
            <h1 className="tituloin">Impresión</h1>
            

            <h2 className="textos">Impresión digital con inyección
                de tintas EPSON Ultrachrome HDX</h2> <br/>
             </div>
             <Container maxWidth="md">
             <img 
             className="pegaso"
             alt="El sueño de Pegaso, Gonzalo Morales"
             src={tabla1}
            
            />
            
            </Container>
            <Container maxWidth="md">
             <img 
             className="pegaso"
             alt="El sueño de Pegaso, Gonzalo Morales"
             src={tabla2}
            
            />
            
            </Container>
            <br/>
            <div className="asteri">
                *Todos los precios incluyen IVA.
            </div>
            <div className="asteri">
                *Incluye una prueba de impresión.
            </div>
            <div className="asteri">
                *Caja Conservativa tipo media almeja, hecha en cartón de agua de 2.5 mm.<br/>
                    Al interior lleva separadores removibles de cartón.
            </div>
            <br/><br/><br/>
            </Container>
 
        </motion.div>
   
        </div>
    )
}

export default Digital
